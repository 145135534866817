<template lang="">
    <div class="userGuideDetail">
        <a-card :bordered="false" class="header-solid h-full p-20" :bodyStyle="{ padding: 0 }">
            <div class="text-center my-50" v-if="isLoadingTutorial">
                <a-spin size="large" />
            </div>
            <div v-if="!isLoadingTutorial">
                <div class="userGuideDetail__title">
                    <h2>{{ tutorialObject.title }}</h2>
                    <MyEditor :data="tutorialObject.content" :isReadOnly="true" />
                </div>
                <div class="userGuideDetail__group" v-for="(item, index) in tutorialObject.tutorial_details">
                    <h4>{{ item.title }}</h4>
                    <MyEditor :data="item.content" :isReadOnly="true" />
                </div>
            </div>
        </a-card>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MyEditor from '../../components/CMS/MyEditor';

export default {
    components: {
        MyEditor,
    },
    computed: {
        ...mapState('tutorial', ['isLoadingTutorial', 'tutorialObject']),
    },
    methods: {
        ...mapActions('tutorial', ['getOneTutorial']),
    },
    async created() {
        await this.getOneTutorial({ id: this.$route.params.id });
    },
};
</script>

<style lang="scss">
.userGuideDetail__title {
    margin-bottom: 30px;
    & h2 {
        margin-bottom: 8px;
        font-size: 28px;
        line-height: 1.4;
    }
    & p {
        font-size: 16px;
    }
}

.userGuideDetail__group {
    margin-bottom: 50px;
    & h4 {
        margin-bottom: 16px;
        padding-left: 10px;
        font-size: 20px;
        border-left: 4px solid #1890ff;
    }
    & p {
        margin-bottom: 0;
        font-size: 16px;
    }
}
</style>
